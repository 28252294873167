import React from "react";
import styled from "styled-components";
import App from "../components/App";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Teaser from "../components/Teaser";
import SEO from "../components/SEO";

function Category(props) {
  const { pageContext: { posts, category }, location } = props;
  const title = `Category: ${category.name}`;

  return (
    <App>
      <Header />
      <SEO page={{ title, pathname: location.pathname }} />
      <Main>
        <CategoryTitle>{title}</CategoryTitle>
        {posts.map(post => <Teaser key={post.id} post={post} />)}
      </Main>
      <Footer />
    </App>
  );
}

const CategoryTitle = styled.h1`
  margin-bottom: 0;
`;

export default Category;
